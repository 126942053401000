import { Component, Input } from '@angular/core';
import { SidenavItem } from '../../../../../../../../library/src/layout/sidenav/sidenav-item/sidenav-item.interface';

@Component({
    selector: 'vr-toolbar-navigation-dropdown-item',
    templateUrl: './toolbar-navigation-dropdown-item.component.html',
    styleUrls: ['./toolbar-navigation-dropdown-item.component.scss']
})
export class ToolbarNavigationDropdownItemComponent {

    @Input() item: SidenavItem;
    @Input() currentlyOpen: SidenavItem[] = [];

    constructor() {
    }

    toggleDropdown() {
    }

    isOpen(item: SidenavItem): boolean {
        return this.currentlyOpen.indexOf(item) > -1;
    }

    // Receives the count of Sub Items and multiplies it with 48 (height of one SidenavItem) to set the height for animation.
    getSubMenuHeight(): string {
        return (this.getSubMenuItemsCount(this.item) * 48).toString() + 'px';
    }

    // Counts the amount of Sub Items there is and returns the count.
    getSubMenuItemsCount(item: SidenavItem): number {
        let count = 0;

        if (item.subItems.length && this.isOpen(item)) {
            count += item.subItems.length;

            item.subItems.forEach((subItem) => {
                count += this.getSubMenuItemsCount(subItem);
            });
        }

        return count;
    }

}
