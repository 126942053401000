import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

export const FORMAT = new InjectionToken('string');

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    private format = 'DD/MM/YYYY';

    constructor(@Inject(FORMAT) format?: string) {
        if (format) {
            this.format = format;
        }
    }

    transform(value: any): any {

        if (!value) {
            return '';
        }

        return moment(value).format(this.format);
    }

}

