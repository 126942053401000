import { Component } from '@angular/core';
import { UserProfile } from '../../../profile/shared/user-profile.class';
import { ProfileService } from '../../../profile/shared/profile.service';
import { BaseComponent } from '../../../../../../library/src/components/base-component.class';
import { ActionService } from '../../../../../../library/src/services/action/action.service';
import { ActionType } from '../../../../../../library/src/services/action/action-type.enum';

@Component({
    selector: 'fury-toolbar-user-button',
    templateUrl: './toolbar-user-button.component.html',
    styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent extends BaseComponent {

    isOpen: boolean;
    profile: UserProfile = new UserProfile();

    constructor(private profileService: ProfileService,
                private actionService: ActionService) {
        super();
        this.loadProfile();
        this.subscribeToProfileChanges();
    }

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    onClickOutside() {
        this.isOpen = false;
    }

    private loadProfile() {
        this.profileService.getProfile().subscribe(profile => this.profile = profile);
    }

    private subscribeToProfileChanges() {
        this.actionService.on(UserProfile, [ActionType.UPDATE]).subscribe(msg => {
            this.profile = msg.data;
        });
    }

}
