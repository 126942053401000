import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'implode'
})
export class ImplodePipe implements PipeTransform {

  constructor() {}

  transform(values: string[], glue: string = ','): any {
    return values.join(glue);
  }

}
