import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'currencyFormat'
})

export class CurrencyFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    const numberPipe = new DecimalPipe('en');

    return numberPipe.transform(value, '1.2-2');
  }

}
