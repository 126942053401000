import { Action } from './action.interface';
import { Subject, Subscriber, Subscription } from 'rxjs';
import { ISubscription } from 'rxjs-compat/Subscription';

export class ActionSubject extends Subject<Action> {

    Actions: Action[] = [];

    constructor() {
        super();
    }

    public _subscribe(subscriber: Subscriber<Action>): Subscription {
        const subscription = super._subscribe(subscriber);

        if (subscription && !(<ISubscription>subscription).closed) {
            this.Actions.forEach(item => {
                subscriber.next(item);
            });
        }
        return subscription;
    }

    protected addAction(action: Action) {

        const existingIndex = this.Actions.findIndex(item => {
            return item.modelType.name === action.modelType.name;
        });

        if (existingIndex !== -1) {
            this.Actions[existingIndex] = action;

        } else {
            this.Actions.push(action);
        }

    }

    getValues(): Action[] {
        return this.Actions;
    }

    next(action: Action): void {
        this.addAction(action);
        super.next(action);
    }
}
