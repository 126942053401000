import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToolbarFullscreenToggleComponent } from './toolbar-fullscreen-toggle/toolbar-fullscreen-toggle.component';
import { ToolbarNotificationsComponent } from './toolbar-notifications/toolbar-notifications.component';
import { ToolbarQuickpanelToggleComponent } from './toolbar-quickpanel-toggle/toolbar-quickpanel-toggle.component';
import { ToolbarSearchBarComponent } from './toolbar-search-bar/toolbar-search-bar.component';
import { ToolbarSearchComponent } from './toolbar-search/toolbar-search.component';
import { ToolbarSidenavMobileToggleComponent } from './toolbar-sidenav-mobile-toggle/toolbar-sidenav-mobile-toggle.component';
import { ToolbarUserButtonComponent } from './toolbar-user-button/toolbar-user-button.component';
import { ToolbarComponent } from './toolbar.component';
import { ToolbarNavigationComponent } from './toolbar-navigation/toolbar-navigation.component';
import { ToolbarNavigationDropdownItemComponent } from './toolbar-navigation/toolbar-navigation-item/toolbar-navigation-dropdown-item/toolbar-navigation-dropdown-item.component';
import { ToolbarNavigationItemComponent } from './toolbar-navigation/toolbar-navigation-item/toolbar-navigation-item.component';
import { MaterialModule } from '../../../../../library/src/material-components.module';
import { DirectivesModule } from '../../../../../library/src/directives/directives.module';
import { FuryCardModule } from '../../../../../library/src/components/card/card.module';
import { ScrollbarModule } from '../../../../../library/src/components/scrollbar/scrollbar.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        // ScrollbarModule,
        FormsModule,
        DirectivesModule,
        FuryCardModule
    ],
    declarations: [
        ToolbarComponent,
        ToolbarUserButtonComponent,
        ToolbarNotificationsComponent,
        ToolbarSearchComponent,
        ToolbarSearchBarComponent,
        ToolbarQuickpanelToggleComponent,
        ToolbarFullscreenToggleComponent,
        ToolbarSidenavMobileToggleComponent,
        ToolbarNavigationDropdownItemComponent,
        ToolbarNavigationItemComponent,
        ToolbarNavigationComponent
    ],
    exports: [
        ToolbarComponent,
        ToolbarNavigationComponent
    ]
})
export class ToolbarModule {
}
