export class UserProfile {

  id: number;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phone: string;
  profileImage: string;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

}
