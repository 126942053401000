import { Injectable, Type } from '@angular/core';
import { ActionSubject } from './action-subject.class';
import { Action } from './action.interface';
import { ActionType } from './action-type.enum';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Cloneable } from 'cloneable-ts';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    private action: ActionSubject;

    constructor() {
        this.action = new ActionSubject();
    }

    private _publish(action: Action): void {
        this.action.next(action);
    }

    private of(classType: any, eventTypes: ActionType[] | string[]): Observable<Action> {
        return this.action
            .pipe(
                filter(m => {
                    for (const eventType of eventTypes) {
                        const type = classType.name + '.' + eventType === m.modelType.name + '.' + m.type;
                        if (type) {
                            return true;
                        }
                    }
                }),
                map(m => m)
            );
    }

    publishSelect(modelType: Type<any>, model: any) {
        this._publish({ modelType: modelType, data: Cloneable.clone(model), type: ActionType.SELECT });
    }

    publishCreate(modelType: Type<any>, model: any) {
        this._publish({ modelType: modelType, data: Cloneable.clone(model), type: ActionType.CREATE });
    }

    publishDelete(modelType: Type<any>, id: number) {
        this._publish({ modelType: modelType, data: id, type: ActionType.DELETE });
    }

    publishUpdate(modelType: Type<any>, model: any) {
        this._publish({ modelType: modelType, data: Cloneable.clone(model), type: ActionType.UPDATE });
    }

    publishCancel(modelType: Type<any>) {
        this._publish({ modelType: modelType, type: ActionType.CANCEL });
    }


    requestUpdate(modelType: Type<any>, id?: any) {
        this._publish({ modelType: modelType, data: id, type: ActionType.REQUEST_UPDATE });
    }

    publish(modelType: Type<any>, action: string, data?: any) {
        this._publish({ modelType: modelType, data: data, type: action });
    }

    publishNew(modelType: Type<any>) {
        this._publish({ modelType: modelType, data: null, type: ActionType.NEW });
    }


    on(modelType: Type<any>, actions: ActionType[] | ActionType | string | string[]): Observable<Action> {

        let actionTypes = [];

        if (!(actions instanceof Array)) {
            actionTypes = [actions];
        } else {
            actionTypes = actions;
        }

        return this.of(modelType, actionTypes);
    }
}
