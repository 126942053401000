import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: '',
    component: LayoutComponent,
    children: [

      { path: '', redirectTo: 'activities', pathMatch: 'full' },

      {
        path: 'activities',
        loadChildren: './activities/activities.module#ActivitiesModule',
      },
      {
        path: 'customers',
        loadChildren: './customers/customers.module#CustomersModule',
      },

      {
        path: 'users',
        loadChildren: './users/users.module#UsersModule',
      },

      {
        path: 'settings/body-areas',
        loadChildren: './settings/body-areas/body-areas.module#BodyAreasModule',
      },

      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      enableTracing: false,
      paramsInheritanceStrategy: 'always'
    }
  )],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule {
}
