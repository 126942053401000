import { NgModule, Optional, SkipSelf } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { LayoutModule } from "./layout/layout.module";
import { MetaLoader, MetaModule, MetaStaticLoader } from "@ngx-meta/core";
import { Error } from "tslint/lib/error";
import { ConfigService } from "../../../../library/src/config/config.service";
import { ConfigurationModule } from "../../../../library/src/config/configuration.module";
import { PendingInterceptorModule } from "../../../../library/src/components/loading-indicator/pending-interceptor.module";
import { DeviceDetectorModule } from "ngx-device-detector";

export function metaFactory(config: ConfigService): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: config.get("seo.pageTitlePositioning"),
    pageTitleSeparator: config.get("seo.pageTitleSeparator"),
    applicationName: config.get("system.applicationName"),
    defaults: {
      title: config.get("seo.defaultPageTitle"),
      description: config.get("seo.defaultMetaDescription"),
    },
  });
}

@NgModule({
  imports: [
    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

    // Config
    ConfigurationModule,

    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
      deps: [ConfigService],
    }),
    DeviceDetectorModule.forRoot(),
  ],
  providers: [MatIconRegistry],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        "CoreModule is already loaded. Import it in the AppModule only.",
      );
    }
  }
}
