import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import 'hammerjs'; // Needed for Touch functionality of Material Components
import { environment } from '../environments/environment';
import { RoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OauthInterceptor } from './auth/shared/ouath.interceptor';
import { ApiInterceptor } from '../../../library/src/services/api/api.interceptor';
import { HttpErrorInterceptor } from '../../../library/src/services/http-error.interceptor';

@NgModule({
    declarations: [AppComponent],
    imports: [
        // Angular Core Module // Don't remove!
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // Project Core Modules
        CoreModule,
        RoutingModule,
        MatSnackBarModule,

        // Register a Service Worker (optional)
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),

    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OauthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }

    ]
})
export class AppModule {
}
