export class SidenavItem {
    name: string;
    icon?: string;
    routeOrFunction?: any;
    parent?: SidenavItem;
    subItems?: SidenavItem[] = [];
    position?: number;
    pathMatchExact?: boolean;
    badge?: string;
    badgeColor?: string;
    type?: 'item' | 'subheading';
    customClass?: string;

    get generateLetterIcon(): string {
        return this.name.substr(0, 1);
    }

    constructor(data: any) {
        Object.assign(this, data);
    }
}
