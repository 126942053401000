import { Injectable } from '@angular/core';
import { ConfigService as Config } from '@ngx-config/core';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(private configService: Config) {
    }

    get apiUrl(): string {
        return this.configService.getSettings('system.apiUrl');
    }

    get apiPrefix(): string {
        return this.configService.getSettings('system.apiPrefix');
    }

    get apiFullUrl(): string {
        return this.apiUrl + '/' + this.apiPrefix;
    }

    get clientId(): string {
        return this.configService.getSettings('system.clientId');
    }

    get clientSecret(): string {
        return this.configService.getSettings('system.clientSecret');
    }

    get appName(): string {
        return this.configService.getSettings('system.applicationName');
    }

    get(key?: string | Array<string>, defaultValue?: any): any {
        return this.configService.getSettings(key) || defaultValue;
    }

}
