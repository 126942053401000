import { Component } from '@angular/core';
import { SidenavService } from '../../../library/src/layout/sidenav/sidenav.service';
import { SidenavItem } from '../../../library/src/layout/sidenav/sidenav-item/sidenav-item.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(sidenavService: SidenavService) {
    const menu: SidenavItem[] = [];

    menu.push(new SidenavItem({
      name: 'Calendar',
      routeOrFunction: '/activities',
      icon: 'calendar_today',
      position: 1
    }));
    
    menu.push(new SidenavItem({
      name: 'Customers',
      routeOrFunction: '/customers',
      icon: 'supervisor_account',
      position: 2,
      pathMatchExact: true
    }));

    menu.push(new SidenavItem({
      name: 'Settings',
      type: 'subheading',
      icon: 'settings',
      position: 3
    }));

    menu.push(new SidenavItem({
      name: 'Users',
      routeOrFunction: '/users',
      icon: 'perm_identity',
      position: 4
    }));


    menu.push(new SidenavItem(
      {
        name: 'Body Areas',
        routeOrFunction: '/settings/body-areas',
        icon: 'accessibility',
        position: 6
      }
    ));


    // menu.push({
    //     name: 'Components',
    //     routeOrFunction: '/components',
    //     icon: 'layers',
    //     position: 40
    // });

    // const formElements = {
    //     name: 'Form Elements',
    //     routeOrFunction: '/forms/form-elements',
    //     position: 10
    // };
    //
    // const formWizard = {
    //     name: 'Form Wizard',
    //     routeOrFunction: '/forms/form-wizard',
    //     position: 15
    // };
    //
    // menu.push({
    //     name: 'Forms',
    //     icon: 'description',
    //     position: 45,
    //     subItems: [
    //         formElements,
    //         formWizard
    //     ]
    // });
    //
    // const simpleTable = {
    //     name: 'Simple Table',
    //     routeOrFunction: '/tables/simple-table',
    //     position: 10
    // };
    //
    // const allInOneTable = {
    //     name: 'All-In-One Table',
    //     routeOrFunction: '/tables/all-in-one-table',
    //     position: 15
    // };
    //
    // menu.push({
    //     name: 'Tables',
    //     icon: 'format_line_spacing',
    //     position: 50,
    //     subItems: [
    //         simpleTable,
    //         allInOneTable
    //     ]
    // });
    //
    // menu.push({
    //     name: 'Drag & Drop',
    //     routeOrFunction: '/drag-and-drop',
    //     icon: 'mouse',
    //     position: 55
    // });
    //
    // menu.push({
    //     name: 'WYSIWYG Editor',
    //     routeOrFunction: '/editor',
    //     icon: 'format_shapes',
    //     position: 60
    // });
    //
    // menu.push({
    //     name: 'PAGES',
    //     type: 'subheading',
    //     position: 65
    // });
    //
    // const googleMaps = {
    //     name: 'Google Maps',
    //     routeOrFunction: '/maps/google-maps',
    //     position: 5
    // };
    //
    // menu.push({
    //     name: 'Maps',
    //     icon: 'map',
    //     position: 70,
    //     subItems: [
    //         googleMaps
    //     ],
    //     badge: '3',
    //     badgeColor: '#4CAF50'
    // });
    //
    // menu.push({
    //     name: 'Material Icons',
    //     routeOrFunction: '/icons',
    //     icon: 'grade',
    //     position: 75
    // });
    //
    // const login = {
    //     name: 'Login Page',
    //     routeOrFunction: '/login',
    //     position: 5
    // };
    //
    // const register = {
    //     name: 'Register Page',
    //     routeOrFunction: '/register',
    //     position: 10
    // };
    //
    // const forgotPassword = {
    //     name: 'Forgot Password',
    //     routeOrFunction: '/forgot-password',
    //     position: 15
    // };
    //
    // menu.push({
    //     name: 'Custom Pages',
    //     icon: 'web',
    //     position: 80,
    //     subItems: [
    //         login,
    //         register,
    //         forgotPassword
    //     ]
    // });

    // Send all created Items to SidenavService
    menu.forEach(item => sidenavService.addItem(item));
  }
}
