import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LayoutComponent} from './layout.component';
import {LoadingIndicatorModule} from '../../../../../library/src/components/loading-indicator/loading-indicator.module';
import {SidenavModule} from '../../../../../library/src/layout/sidenav/sidenav.module';
import {MaterialModule} from '../../../../../library/src/material-components.module';
import {ToolbarModule} from '../toolbar/toolbar.module';
import {FooterModule} from '../footer/footer.module';
import {BackdropModule} from '../../../../../library/src/components/backdrop/backdrop.module';
import {MediaQueryService} from '../../../../../library/src/services/mediareplay/media-replay.service';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {ScrollbarModule} from '../../../../../library/src/components/scrollbar/scrollbar.module';
import {EmptyLayoutComponent} from './empty-layout.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    LoadingIndicatorModule,

    // Core
    ToolbarModule,
    SidenavModule,
    FooterModule,
    BackdropModule,
    NgScrollbarModule,
    ScrollbarModule
  ],
  declarations: [
    LayoutComponent,
    EmptyLayoutComponent
  ],
  providers: [
    MediaQueryService
  ],
  exports: [
    EmptyLayoutComponent
  ]
})
export class LayoutModule {
}
