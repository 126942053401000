import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ConfigLoader, ConfigModule } from '@ngx-config/core';
import { ConfigHttpLoader } from '@ngx-config/http-loader';


export function configFactory(http: HttpClient): ConfigLoader {
    return new ConfigHttpLoader(http, './assets/config.json'); // API ENDPOINT
}

@NgModule({
    imports: [
        CommonModule,
        ConfigModule.forRoot({
            provide: ConfigLoader,
            useFactory: (configFactory),
            deps: [HttpClient]
        })

    ],
    declarations: []
})
export class ConfigurationModule {
}
