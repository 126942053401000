import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { HumanizeStringPipe } from './humanize-string.pipe';
import { ImplodePipe } from './implode.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { ShortenTextPipe } from './shorten-text.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { TimeZonePipe } from './time-zone.pipe';
import { UcWordsPipe } from './uc-words.pipe';
import { YesNoPipe } from './yes-no.pipe';

@NgModule({
    declarations: [
        CurrencyFormatPipe,
        DateFormatPipe,
        HumanizeStringPipe,
        ImplodePipe,
        SafeUrlPipe,
        ShortenTextPipe,
        SlugifyPipe,
        TimeZonePipe,
        UcWordsPipe,
        YesNoPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CurrencyFormatPipe,
        DateFormatPipe,
        HumanizeStringPipe,
        ImplodePipe,
        SafeUrlPipe,
        ShortenTextPipe,
        SlugifyPipe,
        TimeZonePipe,
        UcWordsPipe,
        YesNoPipe
    ]
})
export class PipesModule {
}
