import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { UserProfile } from './user-profile.class';
import { Observable } from 'rxjs';
import { ChangePassword } from './change-password.class';
import { ApiCrudService } from '../../../../../library/src/services/api/crud/api-crud.service';
import { ConfigService } from '../../../../../library/src/config/config.service';
import { NestedResource } from '../../../../../library/src/services/api/crud/nested-resource.class';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ApiCrudService<UserProfile> {

  resourcePath = '';
  resourceType = UserProfile;

  constructor(public http: HttpClient,
    private authService: AuthService,
    public configService: ConfigService) {
    super(http, configService);
  }

  getProfile(): Observable<UserProfile> {
    return this.getOne(null, new NestedResource('user/profile')).pipe(tap(profile => {
      const user = this.authService.getUser();
      user.id = profile.id;
      localStorage.setItem('currentUser', JSON.stringify(user));
    }));
  }

  saveProfile(profile: UserProfile) {
    return this.put(profile, new NestedResource('user/profile'));
  }

  changePassword(form: ChangePassword) {
    return this.put(form, new NestedResource('user/change-password'));
  }


  uploadProfileImage(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const req = new HttpRequest('POST', this.getResourcePath() + 'user/profile/image', formData, {
      headers: headers,
      reportProgress: true
    });

    return this.http.request(req);

  }
}
