import { HttpClient } from '@angular/common/http';

import { Resource } from './resource.interface';
import { ConfigService } from '../../../config/config.service';
import { NestedResource } from './nested-resource.class';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { classToPlain, plainToClass } from 'class-transformer';
import { SearchRequest } from './search-request.class';
import { Type } from '@angular/core';


export class PagedData<T> {

  from: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  to: number;
  total: number;
  data: T[];

}

export class Page {
  perPage: number;
  currentPage: number;

  constructor(perPage: number = 20, currentPage: number = 1) {
    this.perPage = perPage;
    this.currentPage = currentPage;
  }
}


export class ApiCrudService<T> {

  baseUrl: string;
  resourcePath: string;
  resourceType: Type<T>;

  constructor(public http: HttpClient,
    public configService: ConfigService) {

    this.baseUrl = this.configService.apiFullUrl;
  }


  getOne(search?: SearchRequest, onResource?: NestedResource | NestedResource[]): Observable<T> {

    let url = this.baseUrl;
    url = this.appendNestedResourceToUrl(onResource, url) + this.resourcePath;
    url = this.appendSearchToUrl(search, url);
    url = this.removeLastSlash(url);

    return this.http.get<T>(url).pipe(map((response) => {
      return this.handleResponse(response);
    }));
  }


  find(id: number, search?: SearchRequest, onResource?: NestedResource | NestedResource[]): Observable<T> {

    let url = this.baseUrl;
    url = this.appendNestedResourceToUrl(onResource, url) + this.resourcePath + '/' + id;
    url = this.appendSearchToUrl(search, url);
    url = this.removeLastSlash(url);

    return this.http.get<T>(url).pipe(map((response) => {
      return this.handleResponse(response);
    }));
  }


  get(page?: any, search?: SearchRequest, onResource?: NestedResource | NestedResource[]): Observable<PagedData<T>> {

    let url = this.baseUrl;
    url = this.constructUrl(page, search, onResource, url);
    url = this.removeLastSlash(url);

    return this.http.get<PagedData<T>>(url).pipe(map((response) => {
      return this.handlePagedResponse(response);
    }));

  }


  getList(search?: SearchRequest, onResource?: NestedResource | NestedResource[]): Observable<T[]> {

    let url = this.baseUrl;
    url = this.appendNestedResourceToUrl(onResource, url) + this.resourcePath;
    url = this.appendSearchToUrl(search, url);
    url = this.removeLastSlash(url);

    return this.http.get<T[]>(url).pipe(map((response) => {
      return this.deserializeResourceArray(response);
    }));
  }

  delete(id: number, onResource?: NestedResource | NestedResource[]): Observable<T> {

    let url = this.baseUrl;
    url = this.appendNestedResourceToUrl(onResource, url) + this.resourcePath;
    url += '/' + id;
    url = this.removeLastSlash(url);

    return this.http.delete<T>(url).pipe(map((response) => {
      return response;
    }));
  }

  save(resource: Resource | any, onResource?: NestedResource | NestedResource[]): Observable<T> {
    return resource.id ? this.update(resource, onResource) : this.create(resource, onResource);
  }


  create(resource: Resource, onResource?: NestedResource | NestedResource[]): Observable<T> {

    let url = this.baseUrl;
    url = this.appendNestedResourceToUrl(onResource, url) + this.resourcePath;
    url = this.removeLastSlash(url);

    return this.http.post<T>(url, classToPlain(resource)).pipe(map((response) => {
      return this.handleResponse(response);
    }));
  }

  update(resource: Resource | any, onResource?: NestedResource | NestedResource[]): Observable<T> {

    let url = this.baseUrl;
    url = this.appendNestedResourceToUrl(onResource, url);
    url += this.resourcePath;

    if (resource.id) {
      url += '/' + resource.id;
    }

    url = this.removeLastSlash(url);

    return this.http.put<T>(url, classToPlain(resource)).pipe(map((response) => {
      return this.handleResponse(response);
    }));
  }

  getResourcePath() {
    return this.baseUrl + '/' + this.resourcePath;
  }

  protected put(resource: Resource | any, onResource?: NestedResource | NestedResource[]): Observable<T> {

    let url = this.baseUrl;
    url = this.appendNestedResourceToUrl(onResource, url);
    url += this.resourcePath;
    url = this.removeLastSlash(url);

    return this.http.put<T>(url, classToPlain(resource)).pipe(map((response) => {
      return this.handleResponse(response);
    }));
  }

  protected post(resource: Resource | any, onResource?: NestedResource | NestedResource[]): Observable<T> {
    let url = this.baseUrl;
    url = this.appendNestedResourceToUrl(onResource, url);
    url += this.resourcePath;
    url = this.removeLastSlash(url);

    return this.http.post<T>(url, classToPlain(resource)).pipe(map((response) => {
      return this.handleResponse(response);
    }));
  }

  protected handleResponse(response: any): T {
    return plainToClass(this.resourceType, response) as any;
  }

  protected handlePagedResponse(response: any): PagedData<T> {
    const pageData = new PagedData<T>();

    pageData.currentPage = response.currentPage;
    pageData.from = response.from;
    pageData.to = response.to;
    pageData.lastPage = response.lastPage;
    pageData.perPage = +response.perPage;
    pageData.total = response.total;

    pageData.data = this.deserializeResourceArray(response.data);

    return pageData;
  }

  protected deserializeResourceArray(resources: Object[]): T[] {
    return resources.map(item => {
      return plainToClass(this.resourceType, item);
    });
  }


  protected appendNestedResourceToUrl(nestedResources: NestedResource | NestedResource[], url: string) {

    if (url.charAt(url.length - 1) !== '/')
      url += '/';

    if (!nestedResources)
      return url;

    if (!(nestedResources instanceof Array))
      nestedResources = [nestedResources];


    for (const nested of nestedResources) {

      if (nested.id) {
        url += nested.name + '/' + nested.id + '/';
      } else {
        url += nested.name + '/';
      }
    }

    return url;
  }

  protected appendPaginationToUrl(page: Page, url: string): string {

    if (!page)
      return url;

    const pagedString = 'page=' + page.currentPage + '&total=' + page.perPage;

    if (url.contains('?')) {
      url += '&' + pagedString;

    } else {
      url += '?' + pagedString;
    }

    return url;
  }

  protected appendSearchToUrl(search: SearchRequest, url: string): string {

    if (!search) return url;

    if (search.filters.length) {

      let searchString = 'search=';
      let searchFieldsString = 'searchFields=';

      if (search.filters.length === 1 && search.filters[0].key === 'search') {

        searchString += search.filters[0].value;
        url += `&${searchString}`;

      } else {

        search.filters.forEach(item => {
          searchString += `${item.key}:${item.value}`;
          searchFieldsString += `${item.key}:${item.operation}`;
        });

        url += `&${searchString}&${searchFieldsString}`;
      }
    }


    if (search.orderBy) {
      url += `&orderBy=${search.orderBy}&sortedBy=${search.sortedBy}`;
    }




    url += `&searchJoin=${search.searchJoin}`;

    let relations = '';
    if (search.with.length) {
      search.with.forEach(item => {
        relations += `${item};`;
      });

      relations = relations.substring(0, relations.length - 1);
      url += `&with=${relations}`;
    }

    let fields = '';
    if (search.fields.length) {
      search.fields.forEach(item => {
        fields += `${item},`;
      });

      fields = relations.substring(0, fields.length - 1);
      url += `&fields=${fields}`;
    }


    if (search.urlSearchParams.toString() !== '') {
      url += '&' + search.urlSearchParams.toString();
    }


    if (!url.contains('?') && url.contains('&')) {
      url = url.replace('&', '?');
    }


    return url;
  }

  protected constructUrl(page: Page, search: SearchRequest, onResource: NestedResource | NestedResource[], url: string): string {

    url = this.appendNestedResourceToUrl(onResource, url) + this.resourcePath;
    url = this.appendPaginationToUrl(page, url);
    url = this.appendSearchToUrl(search, url);

    return url;
  }


  protected removeLastSlash(url: string): string {

    if (url.charAt(url.length - 1) === '/') {
      return url.slice(0, -1);
    }

    return url;
  }

}
