import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenText'
})
export class ShortenTextPipe implements PipeTransform {

  private _len = 20;

  set len(value: number) {
    this._len = value;
  }

  transform(value: any, args?: any): any {

    if (args && args[0]) {
      this._len = args[0];
    }

    if (value && value.length > this._len) {
      return value.slice(0, this._len) + '...';
    }

    return value;
  }

}
