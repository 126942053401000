import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MAT_DATE_LOCALE,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
} from '@angular/material';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';


@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        DirectivesModule,
        MatListModule,
        DirectivesModule,
        PipesModule,
    ],
    exports: [
        MatInputModule,
        MatTabsModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatToolbarModule,
        MatDialogModule,
        MatMenuModule,
        MatGridListModule,
        MatCardModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatSliderModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatRippleModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatStepperModule,
        FlexLayoutModule,
        DirectivesModule
    ],

    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    ],

    declarations: [

    ],
})
export class MaterialModule {
}
