import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AUTH_LOCAL_STORAGE_KEY } from '../../../glamour-shadows/src/app/auth/shared/auth.service';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {


    constructor(private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {


                        switch (error.status) {

                            case 401:
                                this.handle401();
                                break;

                        }

                        // server-side error
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    }

                    return throwError(errorMessage);
                })
            );
    }


    private handle401() {

        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
        this.router.navigate(['/', 'auth', 'login']);
    }
}
